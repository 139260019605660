import { Pool } from 'pg';
import { DBResult, DBConfig } from './db/types';

const config: DBConfig = {
  user: process.env.DB_USER || 'postgres',
  host: process.env.DB_HOST || 'localhost',
  database: process.env.DB_NAME || 'axiloop',
  password: process.env.DB_PASSWORD || 'postgres',
  port: parseInt(process.env.DB_PORT || '5432'),
};

const pool = new Pool(config);

export async function query(text: string, params?: any[]): Promise<DBResult> {
  return pool.query(text, params);
}

export default pool;