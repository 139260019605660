import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Module, License, Payment } from '../types';
import { generateUUID } from '../../../lib/utils/uuid';

interface StoreState {
  modules: Module[];
  licenses: License[];
  payments: Payment[];
  
  addLicense: (license: License) => void;
  updateLicense: (id: string, updates: Partial<License>) => void;
  
  addPayment: (payment: Payment) => void;
  updatePayment: (id: string, updates: Partial<Payment>) => void;
  
  getModuleStatus: (moduleId: string) => 'free' | 'paid' | 'trial' | 'inactive';
  startTrial: (moduleId: string) => void;
}

export const useStoreStore = create<StoreState>()(
  persist(
    (set, get) => ({
      modules: [] as Module[],
      licenses: [] as License[],
      payments: [] as Payment[],

      addLicense: (license) =>
        set((state) => ({ licenses: [...state.licenses, license] })),

      updateLicense: (id, updates) =>
        set((state) => ({
          licenses: state.licenses.map((l) =>
            l.id === id
              ? { ...l, ...updates, updatedAt: new Date().toISOString() }
              : l
          ),
        })),

      addPayment: (payment) =>
        set((state) => ({ payments: [...state.payments, payment] })),

      updatePayment: (id, updates) =>
        set((state) => ({
          payments: state.payments.map((p) =>
            p.id === id
              ? { ...p, ...updates, updatedAt: new Date().toISOString() }
              : p
          ),
        })),

      getModuleStatus: (moduleId) => {
        if (moduleId === 'store' || moduleId === 'inventory') return 'free';

        const license = get().licenses.find(
          (l) =>
            l.moduleId === moduleId &&
            l.status === 'active' &&
            (!l.expirationDate || new Date(l.expirationDate) > new Date())
        );

        if (!license) return 'inactive';
        return license.paymentId === 'trial' ? 'trial' : 'paid';
      },

      startTrial: (moduleId) => {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);

        const license: License = {
          id: generateUUID(),
          moduleId,
          userId: 'current-user-id',
          paymentId: 'trial',
          status: 'active',
          activationDate: new Date().toISOString(),
          expirationDate: expirationDate.toISOString(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };

        get().addLicense(license);
      },
    }),
    {
      name: 'store-storage',
    }
  )
);