import React, { useState } from 'react';
import {
  Product,
  UnitOfMeasure,
  ProductType,
  UnitType,
  Category,       // Para manejar categorías en el formulario.
} from '../types/index';

import { useInventoryStore } from '../stores/inventoryStore';
import { X, Save, Plus, Trash2 } from 'lucide-react';
import { ImageManager } from './ImageManager';
import { LocationSelector } from './LocationSelector';
import { generateUUID } from '../../../lib/utils/uuid';
import { generateBarcode } from '../utils/productUtils';

interface ProductFormProps {
  onClose: () => void;
  initialData?: Product;
  onSubmit: (productData: Product) => void;
}

export function ProductForm({ onClose, initialData, onSubmit }: ProductFormProps) {
  const { addProduct, updateProduct, categories } = useInventoryStore();
  const [formData, setFormData] = useState<Product>(() => {
    
    if (initialData) return initialData;

    const baseUnit: UnitOfMeasure = {
      id: generateUUID(),
      type: 'unit',
      name: 'Unidad',
      conversionFactor: 1,
      isGenerated: true,
      price: { USD: 0 }, // Inicialización requerida
      purchasePrice: {
        amount: 0,
        currency: 'USD'
      },
      sellingPrice: {
        amount: 0,
        currency: 'USD',
        includesTax: true
      }
    };

    return {
      id: generateUUID(),
      sku: '',
      name: '',
      description: '',
      categoryId: '',
      brand: '',
      model: '',
      purchasePrice: {
        amount: 0,
        currency: 'USD',
        lastUpdate: new Date().toISOString()
      },
      sellingPrice: {
        amount: 0,
        currency: 'USD',
        lastUpdate: new Date().toISOString(),
        includesTax: true
      },
      minStock: 0,
      maxStock: 0,
      trackLots: false,
      requiresExpiration: false,
      baseUnit,
      units: [],
      mainImage: undefined,
      gallery: [],
      stock: {},
      type: 'physical',
      status: 'active',
      tags: [],
      customFields: {},
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
  });

  const addUnit = () => {
    const newUnit: UnitOfMeasure = {
      id: generateUUID(),
      type: 'unit',
      name: '',
      conversionFactor: 1,
      isGenerated: true,
      price: { USD: 0 }, // Agregar propiedad requerida
      purchasePrice: {
        amount: 0,
        currency: 'USD'
      },
      sellingPrice: {
        amount: 0,
        currency: 'USD',
        includesTax: true
      }
    };

    setFormData({
      ...formData,
      units: [...formData.units, newUnit]
    });
  };

  const updateUnit = (index: number, updates: Partial<UnitOfMeasure>) => {
    const updatedUnits = [...formData.units];
    updatedUnits[index] = { ...updatedUnits[index], ...updates };
    setFormData({ ...formData, units: updatedUnits });
  };

  const removeUnit = (index: number) => {
    setFormData({
      ...formData,
      units: formData.units.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Generar códigos de barras si es necesario
    const updatedBaseUnit = formData.baseUnit.isGenerated && !formData.baseUnit.barcode
      ? { ...formData.baseUnit, barcode: generateBarcode() }
      : formData.baseUnit;
      price: formData.baseUnit.price || { USD: 0 };

      const updatedUnits = formData.units.map((unit) => ({
        ...unit, // Extiende las propiedades existentes de la unidad
        barcode: unit.isGenerated && !unit.barcode ? generateBarcode() : unit.barcode, // Genera el código de barras si corresponde
        price: unit.price || { USD: 0 }, // Asegura que la unidad tenga un precio definido
      }));

    const productData: Product = {
      ...formData,
      baseUnit: updatedBaseUnit,
      units: updatedUnits,
      updatedAt: new Date().toISOString()
    };
    onSubmit(productData);
    onClose();
    if (initialData) {
      updateProduct(initialData.id, productData);
    } else {
      addProduct(productData);
    }

    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white flex justify-between items-center p-6 border-b border-gray-200 z-10">
          <h2 className="text-xl font-semibold text-gray-900">
            {initialData ? 'Editar Producto' : 'Nuevo Producto'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Información básica */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">SKU</label>
              <input
                type="text"
                value={formData.sku}
                onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
              <div className="text-red-500 text-sm">
  {formData.sku === '' && 'El SKU es obligatorio.'}
</div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Nombre</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Marca</label>
              <input
                type="text"
                value={formData.brand || ''}
                onChange={(e) => setFormData({ ...formData, brand: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Modelo</label>
              <input
                type="text"
                value={formData.model || ''}
                onChange={(e) => setFormData({ ...formData, model: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Categoría</label>
              <select
                value={formData.categoryId}
                onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Seleccione una categoría</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Tipo</label>
              <select
                value={formData.type}
                onChange={(e) => setFormData({ ...formData, type: e.target.value as ProductType })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="physical">Producto Físico</option>
                <option value="digital">Producto Digital</option>
                <option value="service">Servicio</option>
                <option value="food">Alimento</option>
                <option value="medicine">Medicamento</option>
                <option value="electronics">Electrónico</option>
                <option value="clothing">Ropa</option>
                <option value="other">Otro</option>
              </select>
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Descripción</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Precios */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Precio de Compra</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  value={formData.purchasePrice.amount}
                  onChange={(e) => setFormData({
                    ...formData,
                    purchasePrice: {
                      ...formData.purchasePrice,
                      amount: Number(e.target.value),
                      lastUpdate: new Date().toISOString()
                    }
                  })}
                  className="pl-7 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  min="0"
                  step="0.01"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Precio de Venta</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  value={formData.sellingPrice.amount}
                  onChange={(e) => setFormData({
                    ...formData,
                    sellingPrice: {
                      ...formData.sellingPrice,
                      amount: Number(e.target.value),
                      lastUpdate: new Date().toISOString()
                    }
                  })}
                  className="pl-7 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  min="0"
                  step="0.01"
                  required
                />
              </div>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.sellingPrice.includesTax}
                    onChange={(e) => setFormData({
                      ...formData,
                      sellingPrice: {
                        ...formData.sellingPrice,
                        includesTax: e.target.checked
                      }
                    })}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">Incluye IVA</span>
                </label>
              </div>
            </div>
          </div>

          {/* Control de Stock */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
            <div>
              <label className="block text-sm font-medium text-gray-700">Stock Mínimo</label>
              <input
                type="number"
                value={formData.minStock}
                onChange={(e) => setFormData({ ...formData, minStock: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="0"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Stock Máximo</label>
              <input
                type="number"
                value={formData.maxStock}
                onChange={(e) => setFormData({ ...formData, maxStock: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="0"
              />
            </div>

            <div className="space-y-4">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.trackLots}
                    onChange={(e) => setFormData({ ...formData, trackLots: e.target.checked })}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">Control de Lotes</span>
                </label>
              </div>

              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.requiresExpiration}
                    onChange={(e) => setFormData({ ...formData, requiresExpiration: e.target.checked })}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">Control de Vencimiento</span>
                </label>
              </div>
            </div>
          </div>

          {/* Unidades de Manejo */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900">Unidades de Manejo</h3>
              <button
                type="button"
                onClick={addUnit}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100"
              >
                <Plus className="h-4 w-4" />
                Agregar Unidad
              </button>
            </div>

            {/* Unidad Base */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="text-sm font-medium text-gray-900 mb-4">Unidad Base</h4>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Nombre</label>
                  <input
                    type="text"
                    value={formData.baseUnit.name}
                    onChange={(e) => setFormData({
                      ...formData,
                      baseUnit: { ...formData.baseUnit, name: e.target.value }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Código de Barras</label>
                  <input
                    type="text"
                    value={formData.baseUnit.barcode || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      baseUnit: { ...formData.baseUnit, barcode: e.target.value }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder={formData.baseUnit.isGenerated ? 'Se generará automáticamente' : ''}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">&nbsp;</label>
                  <label className="inline-flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={formData.baseUnit.isGenerated}
                      onChange={(e) => setFormData({
                        ...formData,
                        baseUnit: { ...formData.baseUnit, isGenerated: e.target.checked }
                      })}
                      className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">Generar código automáticamente</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Unidades Adicionales */}
            {formData.units.map((unit, index) => (
              <div key={unit.id} className="border border-gray-200 p-4 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <h4 className="text-sm font-medium text-gray-900">Unidad Adicional {index + 1}</h4>
                  <button
                    type="button"
                    onClick={() => removeUnit(index)}
                    className="text-red-600 hover:text-red-700"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Nombre</label>
                    <input
                      type="text"
                      value={unit.name}
                      onChange={(e) => updateUnit(index, { name: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Factor de Conversión
                    </label>
                    <input
                      type="number"
                      value={unit.conversionFactor}
                      onChange={(e) => updateUnit(index, { conversionFactor: Number(e.target.value) })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      min="1"
                      step="0.01"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Código de Barras</label>
                    <input
                      type="text"
                      value={unit.barcode || ''}
                      onChange={(e) => updateUnit(index, { barcode: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder={unit.isGenerated ? 'Se generará automáticamente' : ''}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={unit.isGenerated}
                        onChange={(e) => updateUnit(index, { isGenerated: e.target.checked })}
                        className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">Generar código automáticamente</span>
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Imágenes */}
          <ImageManager
            mainImage={formData.mainImage}
            gallery={formData.gallery}
            onMainImageChange={(image) => setFormData({ ...formData, mainImage: image })}
            onGalleryChange={(images) => setFormData({ ...formData, gallery: images })}
          />

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              <Save className="h-4 w-4" />
              {initialData ? 'Guardar Cambios' : 'Crear Producto'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}