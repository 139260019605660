import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { 
  Currency, 
  TaxRate, 
  FiscalPrinter, 
  POSTerminal,
  Role,
  User,
  Warehouse,
  CompanyConfig 
} from '../types';

interface ConfigState extends CompanyConfig {
  
  roles: Role[];
  users: User[];
  warehouses: Warehouse[];
  fiscalPrinters: FiscalPrinter[];
  posTerminals: POSTerminal[];
  taxes: TaxRate[];


  // Acciones
  updateCompanyInfo: (info: Partial<CompanyConfig>) => void;
  
  addCurrency: (currency: Currency) => void;
  updateCurrency: (code: string, updates: Partial<Currency>) => void;
  deleteCurrency: (code: string) => void;
  getExchangeRate: (from: string, to: string) => number;
  
  addTax: (tax: TaxRate) => void;
  updateTax: (id: string, updates: Partial<TaxRate>) => void;
  deleteTax: (id: string) => void;
  
  addPrinter: (printer: FiscalPrinter) => void;
  updatePrinter: (id: string, updates: Partial<FiscalPrinter>) => void;
  deletePrinter: (id: string) => void;
  
  addTerminal: (terminal: POSTerminal) => void;
  updateTerminal: (id: string, updates: Partial<POSTerminal>) => void;
  deleteTerminal: (id: string) => void;

  addRole: (role: Role) => void;
  updateRole: (id: string, updates: Partial<Role>) => void;
  deleteRole: (id: string) => void;

  addUser: (user: User) => void;
  updateUser: (id: string, updates: Partial<User>) => void;
  deleteUser: (id: string) => void;

  addWarehouse: (warehouse: Warehouse) => void;
  updateWarehouse: (id: string, updates: Partial<Warehouse>) => void;
  deleteWarehouse: (id: string) => void;
}

export const useConfigStore = create<ConfigState>()(
  persist(
    (set, get) => ({
      // Valores iniciales de CompanyConfig
      name: 'Mi Empresa',
      rif: '',
      address: '',
      phone: '',
      email: '',
      logo: undefined as string | undefined,
      receiptHeader: '',
      receiptFooter: '',
      defaultCurrency: 'USD',
      currencies: [
        {
          code: 'USD',
          name: 'Dólar Estadounidense',
          symbol: '$',
          rate: 1,
          isDefault: true,
          isActive: true
        },
        {
          code: 'VES',
          name: 'Bolívar',
          symbol: 'Bs.',
          rate: 35.5,
          isDefault: false,
          isActive: true
        }
      ],
      exchangeRates: {},
      taxes: [] as TaxRate[],
    roles: [] as Role[],
    users: [] as User[],
    fiscalPrinters: [] as FiscalPrinter[],
    posTerminals: [] as POSTerminal[],
    warehouses: [] as Warehouse[],

      // Implementación de acciones
      updateCompanyInfo: (info) => set((state) => ({ ...state, ...info })),

      addCurrency: (currency) => set((state) => ({
        currencies: [...state.currencies, currency]
      })),

      updateCurrency: (code, updates) => set((state) => ({
        currencies: state.currencies.map(c =>
          c.code === code ? { ...c, ...updates } : c
        )
      })),

      deleteCurrency: (code) => set((state) => ({
        currencies: state.currencies.filter(c => c.code !== code)
      })),

      getExchangeRate: (from, to) => {
        const { currencies } = get();
        const fromCurrency = currencies.find(c => c.code === from);
        const toCurrency = currencies.find(c => c.code === to);
        
        if (!fromCurrency || !toCurrency) return 1;
        return toCurrency.rate / fromCurrency.rate;
      },

      addTax: (tax) => set((state) => ({
        taxes: [...state.taxes, tax]
      })),

      updateTax: (id, updates) => set((state) => ({
        taxes: state.taxes.map(t =>
          t.id === id ? { ...t, ...updates } : t
        )
      })),

      deleteTax: (id) => set((state) => ({
        taxes: state.taxes.filter(t => t.id !== id)
      })),

      addPrinter: (printer) => set((state) => ({
        fiscalPrinters: [...state.fiscalPrinters, printer]
      })),

      updatePrinter: (id, updates) => set((state) => ({
        fiscalPrinters: state.fiscalPrinters.map(p =>
          p.id === id ? { ...p, ...updates } : p
        )
      })),

      deletePrinter: (id) => set((state) => ({
        fiscalPrinters: state.fiscalPrinters.filter(p => p.id !== id)
      })),

      addTerminal: (terminal) => set((state) => ({
        posTerminals: [...state.posTerminals, terminal]
      })),

      updateTerminal: (id, updates) => set((state) => ({
        posTerminals: state.posTerminals.map(t =>
          t.id === id ? { ...t, ...updates } : t
        )
      })),

      deleteTerminal: (id) => set((state) => ({
        posTerminals: state.posTerminals.filter(t => t.id !== id)
      })),

      addRole: (role) => set((state) => ({
        roles: [...state.roles, role]
      })),

      updateRole: (id, updates) => set((state) => ({
        roles: state.roles.map(r =>
          r.id === id ? { ...r, ...updates } : r
        )
      })),

      deleteRole: (id) => set((state) => ({
        roles: state.roles.filter(r => r.id !== id)
      })),

      addUser: (user) => set((state) => ({
        users: [...state.users, user]
      })),

      updateUser: (id, updates) => set((state) => ({
        users: state.users.map(u =>
          u.id === id ? { ...u, ...updates } : u
        )
      })),

      deleteUser: (id) => set((state) => ({
        users: state.users.filter(u => u.id !== id)
      })),

      addWarehouse: (warehouse) => set((state) => ({
        warehouses: [...state.warehouses, warehouse]
      })),

      updateWarehouse: (id, updates) => set((state) => ({
        warehouses: state.warehouses.map(w =>
          w.id === id ? { ...w, ...updates } : w
        )
      })),

      deleteWarehouse: (id) => set((state) => ({
        warehouses: state.warehouses.filter(w => w.id !== id)
      }))
    }),
    {
      name: 'config-storage'
    }
  )
);