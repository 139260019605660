import React, { useState } from 'react';
import { X, CreditCard, DollarSign, Receipt, Smartphone, Mail } from 'lucide-react';
import { useConfigStore } from '../../config/stores/configStore';
import { PaymentMethod, MobilePayment, ZellePayment } from '../types';

interface PaymentModalProps {
  total: number;
  customerId: string;
  customerBalance: number;
  onClose: () => void;
  onComplete: (paymentDetails: any) => void;
}

const PAYMENT_METHODS: PaymentMethod[] = [
  {
    id: 'cash',
    type: 'cash',
    name: 'Efectivo',
    enabled: true,
    requiresReference: false,
    allowsChange: true,
    allowedChangeMethods: ['cash', 'mobile', 'balance']
  },
  {
    id: 'card',
    type: 'card',
    name: 'Tarjeta',
    enabled: true,
    requiresReference: true,
    allowsChange: false,
    allowedChangeMethods: []
  },
  {
    id: 'mobile',
    type: 'mobile',
    name: 'Pago Móvil',
    enabled: true,
    requiresReference: true,
    allowsChange: true,
    allowedChangeMethods: ['cash', 'mobile', 'balance']
  },
  {
    id: 'zelle',
    type: 'zelle',
    name: 'Zelle',
    enabled: true,
    requiresReference: true,
    allowsChange: true,
    allowedChangeMethods: ['cash', 'mobile', 'balance']
  },
  {
    id: 'balance',
    type: 'balance',
    name: 'Saldo a Favor',
    enabled: true,
    requiresReference: false,
    allowsChange: false,
    allowedChangeMethods: []
  }
];

export function PaymentModal({ total, customerId, customerBalance, onClose, onComplete }: PaymentModalProps) {
  const { currencies, defaultCurrency, getExchangeRate } = useConfigStore();
  const secondaryCurrency = currencies.find(c => c.code === 'VES');
  const exchangeRate = secondaryCurrency ? getExchangeRate(defaultCurrency, secondaryCurrency.code) : 0;

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS[0]);
  const [amountUSD, setAmountUSD] = useState(total);
  const [amountBs, setAmountBs] = useState(total * exchangeRate);
  const [reference, setReference] = useState('');
  const [changeMethod, setChangeMethod] = useState<'cash' | 'mobile' | 'balance'>('cash');
  const [addToBalance, setAddToBalance] = useState(false);
  
  // Campos para pago móvil
  const [mobilePayment, setMobilePayment] = useState<MobilePayment>({
    phoneNumber: '',
    bankName: '',
    referenceNumber: '',
    amount: 0,
    referenceDate: new Date().toISOString().split('T')[0]
  });

  // Campos para Zelle
  const [zellePayment, setZellePayment] = useState<ZellePayment>({
    email: '',
    referenceNumber: '',
    amount: 0,
    referenceDate: new Date().toISOString().split('T')[0]
  });

  const handleUSDChange = (value: number) => {
    setAmountUSD(value);
    setAmountBs(value * exchangeRate);
  };

  const handleBsChange = (value: number) => {
    setAmountBs(value);
    setAmountUSD(value / exchangeRate);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const change = amountUSD - total;
    const paymentDetails = {
      id: crypto.randomUUID(),
      method: paymentMethod.type,
      amountUSD,
      amountBs,
      exchangeRate,
      reference,
      details: {
        change: change > 0 ? change : 0,
        changeMethod: change > 0 ? changeMethod : undefined,
        mobilePayment: paymentMethod.type === 'mobile' ? mobilePayment : undefined,
        zellePayment: paymentMethod.type === 'zelle' ? zellePayment : undefined,
        addToBalance
      }
    };

    onComplete(paymentDetails);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold text-gray-900">Procesar Pago</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {customerBalance > 0 && (
            <div className="bg-blue-50 p-4 rounded-md">
              <p className="text-sm text-blue-700">
                Saldo a favor: ${customerBalance.toFixed(2)}
              </p>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Método de Pago
            </label>
            <div className="grid grid-cols-3 gap-3">
              {PAYMENT_METHODS.map((method) => (
                <button
                  key={method.id}
                  type="button"
                  onClick={() => setPaymentMethod(method)}
                  disabled={method.type === 'balance' && customerBalance <= 0}
                  className={`flex flex-col items-center p-4 border rounded-lg ${
                    paymentMethod.id === method.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-500'
                  } ${method.type === 'balance' && customerBalance <= 0 ? 'opacity-50' : ''}`}
                >
                  {method.type === 'cash' && <DollarSign className="h-6 w-6 text-gray-400" />}
                  {method.type === 'card' && <CreditCard className="h-6 w-6 text-gray-400" />}
                  {method.type === 'mobile' && <Smartphone className="h-6 w-6 text-gray-400" />}
                  {method.type === 'zelle' && <Mail className="h-6 w-6 text-gray-400" />}
                  {method.type === 'balance' && <Receipt className="h-6 w-6 text-gray-400" />}
                  <span className="mt-2 text-sm font-medium text-gray-900">{method.name}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Monto en USD
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  value={amountUSD}
                  onChange={(e) => handleUSDChange(Number(e.target.value))}
                  min={paymentMethod.type === 'balance' ? 0 : total}
                  step="0.01"
                  className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Monto en Bs
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">Bs.</span>
                </div>
                <input
                  type="number"
                  value={amountBs}
                  onChange={(e) => handleBsChange(Number(e.target.value))}
                  min={paymentMethod.type === 'balance' ? 0 : total * exchangeRate}
                  step="0.01"
                  className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 pr-12 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            {paymentMethod.type === 'mobile' && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Número de Teléfono
                  </label>
                  <input
                    type="tel"
                    value={mobilePayment.phoneNumber}
                    onChange={(e) => setMobilePayment({...mobilePayment, phoneNumber: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Banco
                  </label>
                  <input
                    type="text"
                    value={mobilePayment.bankName}
                    onChange={(e) => setMobilePayment({...mobilePayment, bankName: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Número de Referencia
                  </label>
                  <input
                    type="text"
                    value={mobilePayment.referenceNumber}
                    onChange={(e) => setMobilePayment({...mobilePayment, referenceNumber: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Fecha de Referencia
                  </label>
                  <input
                    type="date"
                    value={mobilePayment.referenceDate}
                    onChange={(e) => setMobilePayment({...mobilePayment, referenceDate: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
              </div>
            )}

            {paymentMethod.type === 'zelle' && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Correo Electrónico
                  </label>
                  <input
                    type="email"
                    value={zellePayment.email}
                    onChange={(e) => setZellePayment({...zellePayment, email: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Número de Referencia
                  </label>
                  <input
                    type="text"
                    value={zellePayment.referenceNumber}
                    onChange={(e) => setZellePayment({...zellePayment, referenceNumber: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Fecha de Referencia
                  </label>
                  <input
                    type="date"
                    value={zellePayment.referenceDate}
                    onChange={(e) => setZellePayment({...zellePayment, referenceDate: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
              </div>
            )}

            {amountUSD > total && paymentMethod.allowsChange && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Método de Vuelto
                  </label>
                  <select
                    value={changeMethod}
                    onChange={(e) => setChangeMethod(e.target.value as any)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  >
                    {paymentMethod.allowedChangeMethods.map((method) => (
                      <option key={method} value={method}>
                        {method === 'cash' && 'Efectivo'}
                        {method === 'mobile' && 'Pago Móvil'}
                        {method === 'balance' && 'Agregar a Saldo'}
                      </option>
                    ))}
                  </select>
                </div>

                {changeMethod === 'balance' && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={addToBalance}
                      onChange={(e) => setAddToBalance(e.target.checked)}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Agregar vuelto al saldo del cliente
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              <Receipt className="h-4 w-4" />
              Procesar Pago
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}