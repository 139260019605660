import React, { useState } from 'react';
import { useConfigStore } from '../stores/configStore';
import { Building2 } from 'lucide-react';
import { ImageUploader } from '../../inventory/components/ImageUploader';
import { CompanyConfig as CompanyConfigType } from '../types';

export function CompanyConfig() {
  const config = useConfigStore();
  const [formData, setFormData] = useState<CompanyConfigType>({
    name: config.name || '',
    rif: config.rif || '',
    address: config.address || '',
    phone: config.phone || '',
    email: config.email || '',
    logo: config.logo || '',
    receiptHeader: config.receiptHeader || '',
    receiptFooter: config.receiptFooter || '',
    defaultCurrency: config.defaultCurrency || 'USD',
    taxes: config.taxes || [],
    currencies: config.currencies || [],
    exchangeRates: config.exchangeRates || {}
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    config.updateCompanyInfo(formData);
  };

  const handleImageUpload = (imageData: { url: string }) => {
    setFormData({ ...formData, logo: imageData.url });
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 dark:text-white flex items-center gap-2 mb-4">
        <Building2 className="h-5 w-5" />
        Datos de la Empresa
      </h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* ... resto del formulario ... */}
      </form>
    </div>
  );
}