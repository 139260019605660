import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { POSSession, CashMovement } from '../types';

interface POSState {
  currentSession: POSSession | null;
  sessions: POSSession[];
  movements: CashMovement[];

  openSession: (data: Omit<POSSession, 'id' | 'openedAt' | 'status' | 'sales' | 'payments' | 'transactions'>) => void;
  closeSession: (data: { finalCash: number }) => void;
  addMovement: (data: Omit<CashMovement, 'id' | 'createdAt'>) => void;

  // Verificar permisos (puedes ajustar la lógica según sea necesario)
  hasTerminalAccess: (userId: string, terminalId: string) => boolean;
}

export const usePOSStore = create<POSState>()(
  persist(
    (set, get) => ({
      currentSession: null as POSSession | null, // Tipo explícito para `currentSession`
      sessions: [] as POSSession[],             // Tipo explícito para `sessions`
      movements: [] as CashMovement[],          // Tipo explícito para `movements`

      openSession: (data) => {
        const existingSession = get().sessions.find(
          (s) => s.terminalId === data.terminalId && s.status === 'open'
        );

        if (existingSession) {
          throw new Error('Ya existe una sesión abierta para este terminal');
        }

        const session: POSSession = {
          id: crypto.randomUUID(),
          ...data,
          openedAt: new Date().toISOString(),
          status: 'open',
          sales: [],
          payments: [],
          transactions: [],
        };

        set((state) => ({
          sessions: [...state.sessions, session],
          currentSession: session,
        }));
      },

      closeSession: (data) => {
        set((state) => ({
          sessions: state.sessions.map((s) =>
            s.id === state.currentSession?.id
              ? {
                  ...s,
                  ...data,
                  closedAt: new Date().toISOString(),
                  status: 'closed',
                }
              : s
          ),
          currentSession: null,
        }));
      },

      addMovement: (data) => {
        const movement: CashMovement = {
          id: crypto.randomUUID(),
          ...data,
          createdAt: new Date().toISOString(),
        };

        set((state) => ({
          movements: [...state.movements, movement],
        }));
      },

      hasTerminalAccess: (userId, terminalId) => {
        // Agregar lógica personalizada para verificar permisos
        return true;
      },
    }),
    {
      name: 'pos-storage',
    }
  )
);
