import { Product, UnitOfMeasure, StockEntry } from '../types/product';

export function calculateTotalStock(
  product: Product, 
  warehouseId: string,
  unitId: string
): number {
  const warehouseStock = product.stock[warehouseId];
  if (!warehouseStock) return 0;

  const unitStock = warehouseStock[unitId];
  if (!unitStock) return 0;

  return unitStock.reduce((total, entry) => 
    entry.status === 'available' ? total + entry.quantity : total, 
    0
  );
}

export function calculateStockValue(
  product: Product,
  warehouseId: string
): number {
  let totalValue = 0;
  const warehouseStock = product.stock[warehouseId];
  
  if (!warehouseStock) return 0;

  Object.entries(warehouseStock).forEach(([unitId, entries]) => {
    const unit = unitId === product.baseUnit.id 
      ? product.baseUnit 
      : product.units.find(u => u.id === unitId);

    if (!unit) return;

    const availableStock = entries.reduce((total, entry) => 
      entry.status === 'available' ? total + entry.quantity : total,
      0
    );

    const unitPrice = unit.purchasePrice?.amount || product.purchasePrice.amount;
    totalValue += availableStock * unitPrice * unit.conversionFactor;
  });

  return totalValue;
}

export function generateBarcode(): string {
  // Prefijo para productos internos (ejemplo: 200)
  const prefix = '200';
  
  // Generar 9 dígitos aleatorios
  const random = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
  
  // Concatenar prefijo y número aleatorio
  const code = prefix + random;
  
  // Calcular dígito verificador (algoritmo EAN-13)
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    const digit = parseInt(code[i]);
    sum += digit * (i % 2 === 0 ? 1 : 3);
  }
  const checkDigit = (10 - (sum % 10)) % 10;
  
  return code + checkDigit;
}

export function validateBarcode(barcode: string): boolean {
  if (!/^\d{13}$/.test(barcode)) return false;

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    const digit = parseInt(barcode[i]);
    sum += digit * (i % 2 === 0 ? 1 : 3);
  }
  const checkDigit = (10 - (sum % 10)) % 10;
  
  return parseInt(barcode[12]) === checkDigit;
}

export function getExpiringProducts(
  product: Product,
  warehouseId: string,
  daysThreshold: number
): StockEntry[] {
  if (!product.requiresExpiration) return [];

  const warehouseStock = product.stock[warehouseId];
  if (!warehouseStock) return [];

  const now = new Date();
  const threshold = new Date();
  threshold.setDate(threshold.getDate() + daysThreshold);

  const expiring: StockEntry[] = [];

  Object.values(warehouseStock).forEach(entries => {
    entries.forEach(entry => {
      if (
        entry.status === 'available' &&
        entry.expirationDate &&
        new Date(entry.expirationDate) <= threshold &&
        new Date(entry.expirationDate) > now
      ) {
        expiring.push(entry);
      }
    });
  });

  return expiring;
}

export function calculateReorderPoint(
  product: Product,
  warehouseId: string
): boolean {
  const totalStock = calculateTotalStock(
    product,
    warehouseId,
    product.baseUnit.id
  );

  return totalStock <= product.minStock;
}

export function convertUnit(
  quantity: number,
  fromUnit: UnitOfMeasure,
  toUnit: UnitOfMeasure
): number {
  return (quantity * fromUnit.conversionFactor) / toUnit.conversionFactor;
}