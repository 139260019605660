import { Pool } from 'pg';
import bcrypt from 'bcryptjs';
import { User, UserPermission } from '../../stores/auth';
import pool from '../db';

interface RegisterData {
  name: string;
  email: string;
  password: string;
  company?: string;
  country?: string;
  phone?: string;
}

export async function register(userData: RegisterData): Promise<{ success: boolean; user: User }> {
  try {
    // Verificar si el usuario ya existe
    const existingUser = await pool.query(
      'SELECT * FROM users WHERE email = $1',
      [userData.email]
    );
    
    if (existingUser.rows.length > 0) {
      throw new Error('Email already registered');
    }

    // Hash de la contraseña
    const passwordHash = await bcrypt.hash(userData.password, 10);

    // Crear usuario en la base de datos
    const userId = crypto.randomUUID();
    const result = await pool.query(
      `INSERT INTO users (id, name, email, password_hash, role, status)
       VALUES ($1, $2, $3, $4, $5, $6)
       RETURNING *`,
      [userId, userData.name, userData.email, passwordHash, 'user', 'active']
    );

    const user = result.rows[0];

    return {
      success: true,
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
        permissions: [{
          module: 'pos',
          actions: ['read', 'write'],
          warehouses: ['default'],
          terminals: ['default']
        }]
      }
    };
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
}

export async function login(email: string, password: string): Promise<User> {
  try {
    const result = await pool.query(
      'SELECT * FROM users WHERE email = $1',
      [email]
    );

    if (result.rows.length === 0) {
      throw new Error('Invalid credentials');
    }

    const user = result.rows[0];
    const isValid = await bcrypt.compare(password, user.password_hash);
    if (!isValid) {
      throw new Error('Invalid credentials');
    }

    return {
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
      permissions: [{
        module: 'pos',
        actions: ['read', 'write'],
        warehouses: ['default'],
        terminals: ['default']
      }]
    };
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}