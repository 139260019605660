import React, { useState } from 'react';
import { useConfigStore } from '../stores/configStore';
import { DollarSign, Percent, Save } from 'lucide-react';

export function CurrencyTaxSettings() {
  const { currencies, taxes, updateCurrency, updateTax } = useConfigStore();
  const [exchangeRate, setExchangeRate] = useState(
    currencies.find(c => c.code === 'VES')?.rate || 0
  );
  const [taxRate, setTaxRate] = useState(
    taxes.find(t => t.isDefault)?.rate || 16
  );

  const handleExchangeRateSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const ves = currencies.find(c => c.code === 'VES');
    if (ves) {
      updateCurrency(ves.code, {
        ...ves,
        rate: exchangeRate,
        updatedAt: new Date().toISOString()
      });
    }
  };

  const handleTaxRateSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const defaultTax = taxes.find(t => t.isDefault);
    if (defaultTax) {
      updateTax(defaultTax.id, {
        ...defaultTax,
        rate: taxRate,
        updatedAt: new Date().toISOString()
      });
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Tasa de Cambio */}
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex items-center gap-2 mb-4">
          <DollarSign className="h-5 w-5 text-blue-500" />
          <h3 className="text-lg font-medium text-gray-900">Tasa de Cambio</h3>
        </div>

        <form onSubmit={handleExchangeRateSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              1 USD = VES
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="number"
                value={exchangeRate}
                onChange={(e) => setExchangeRate(Number(e.target.value))}
                step="0.0001"
                min="0"
                className="block w-full pr-12 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">Bs.</span>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Save className="h-4 w-4" />
            Actualizar Tasa
          </button>
        </form>
      </div>

      {/* IVA */}
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex items-center gap-2 mb-4">
          <Percent className="h-5 w-5 text-green-500" />
          <h3 className="text-lg font-medium text-gray-900">IVA</h3>
        </div>

        <form onSubmit={handleTaxRateSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Porcentaje de IVA
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="number"
                value={taxRate}
                onChange={(e) => setTaxRate(Number(e.target.value))}
                step="0.01"
                min="0"
                max="100"
                className="block w-full pr-12 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <Save className="h-4 w-4" />
            Actualizar IVA
          </button>
        </form>
      </div>
    </div>
  );
}