import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface UserPermission {
  module: string;
  actions: string[];
  warehouses?: string[];
  terminals?: string[];
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  permissions: UserPermission[];
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null as User | null, // Especifica explícitamente el tipo
      isAuthenticated: false,
      login: (user: User) => set({ user, isAuthenticated: true }),
      logout: () => set({ user: null, isAuthenticated: false }),
    }),
    {
      name: 'auth-storage'
    }
  )
);
