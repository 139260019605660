import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image as ImageIcon, Upload, X } from 'lucide-react';
import { ProductImage } from '../types/product';
import { generateUUID } from '../../../lib/utils/uuid';

interface ImageManagerProps {
  mainImage?: ProductImage;
  gallery: ProductImage[];
  onMainImageChange: (image: ProductImage | undefined) => void;
  onGalleryChange: (images: ProductImage[]) => void;
}

export function ImageManager({
  mainImage,
  gallery,
  onMainImageChange,
  onGalleryChange
}: ImageManagerProps) {
  const onMainDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const newImage: ProductImage = {
        id: generateUUID(),
        url: reader.result as string,
        alt: file.name,
        type: 'main'
      };
      onMainImageChange(newImage);
    };
    reader.readAsDataURL(file);
  }, [onMainImageChange]);

  const onGalleryDrop = useCallback((acceptedFiles: File[]) => {
    const newImages: ProductImage[] = [];
    
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        newImages.push({
          id: generateUUID(),
          url: reader.result as string,
          alt: file.name,
          type: 'gallery'
        });
        
        if (newImages.length === acceptedFiles.length) {
          onGalleryChange([...gallery, ...newImages]);
        }
      };
      reader.readAsDataURL(file);
    });
  }, [gallery, onGalleryChange]);

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    onDrop: onMainDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    },
    maxFiles: 1
  });

  const { getRootProps: getGalleryRootProps, getInputProps: getGalleryInputProps } = useDropzone({
    onDrop: onGalleryDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    }
  });

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Imagen Principal
        </label>
        {mainImage ? (
          <div className="relative">
            <img
              src={mainImage.url}
              alt={mainImage.alt}
              className="w-full h-48 object-cover rounded-lg"
            />
            <button
              type="button"
              onClick={() => onMainImageChange(undefined)}
              className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ) : (
          <div
            {...getMainRootProps()}
            className="flex flex-col items-center justify-center w-full h-48 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500"
          >
            <input {...getMainInputProps()} />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Upload className="h-8 w-8 text-gray-400" />
              <p className="mt-2 text-sm text-gray-500">
                Arrastre una imagen o haga clic para seleccionar
              </p>
            </div>
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Galería de Imágenes
        </label>
        <div className="grid grid-cols-4 gap-4">
          {gallery.map((image) => (
            <div key={image.id} className="relative">
              <img
                src={image.url}
                alt={image.alt}
                className="w-full h-32 object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={() => onGalleryChange(gallery.filter(img => img.id !== image.id))}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          ))}
          
          <div
            {...getGalleryRootProps()}
            className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500"
          >
            <input {...getGalleryInputProps()} />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Upload className="h-6 w-6 text-gray-400" />
              <p className="mt-2 text-xs text-gray-500">Agregar imagen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}