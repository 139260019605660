import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingCart, Package, DollarSign, Receipt } from 'lucide-react';
import { ProductSearch } from './ProductSearch';
import { CartPanel } from './CartPanel';
import { PaymentModal } from './PaymentModal';
import { CustomerSelect } from './CustomerSelect';
import { CashRegisterDialog } from './CashRegisterDialog';
import { useInventoryStore } from '../../inventory/stores/inventoryStore';
import { useConfigStore } from '../../config/stores/configStore';
import { useAuthStore } from '../../../stores/auth';
import { usePOSStore } from '../stores/posStore';
import { Sale, Customer } from '../types';
import pool from '../../../lib/db';

export function POSDashboard() {
  const navigate = useNavigate();
  const { selectedWarehouse } = useInventoryStore();
  const { taxes } = useConfigStore();
  const { user } = useAuthStore();
  const { currentSession } = usePOSStore();
  
  const [showPayment, setShowPayment] = useState(false);
  const [cart, setCart] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [showCashDialog, setShowCashDialog] = useState(false);
  const [cashDialogType, setCashDialogType] = useState<'open' | 'close'>('open');
  const [customerBalance, setCustomerBalance] = useState(0);

  // Verificar permisos
  const hasAccess = user?.role === 'admin' || user?.permissions?.some(p => 
    p.module === 'all' || p.module === 'pos'
  );

  // Si no hay sesión abierta y el usuario tiene acceso, mostrar diálogo de apertura
  useEffect(() => {
    if (!currentSession && hasAccess) {
      setShowCashDialog(true);
      setCashDialogType('open');
    }
  }, [currentSession, hasAccess]);

  // Cargar saldo del cliente cuando se selecciona
  useEffect(() => {
    if (selectedCustomer) {
      loadCustomerBalance();
    }
  }, [selectedCustomer]);

  const loadCustomerBalance = async () => {
    if (!selectedCustomer) return;
    try {
      const result = await pool.query(
        'SELECT amount FROM customer_balances WHERE customer_id = $1',
        [selectedCustomer.id]
      );
      setCustomerBalance(result.rows[0]?.amount || 0);
    } catch (error) {
      console.error('Error al cargar saldo del cliente:', error);
    }
  };

  if (!hasAccess) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            No tiene permisos para acceder al punto de venta
          </h2>
        </div>
      </div>
    );
  }

  // Si no hay sesión, mostrar botón de apertura
  if (!currentSession) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            No hay una sesión de caja abierta
          </h2>
          <button
            onClick={() => {
              setCashDialogType('open');
              setShowCashDialog(true);
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Abrir Caja
          </button>
        </div>
      </div>
    );
  }

  const handleAddToCart = (product: any, quantity: number = 1) => {
    const existingItem = cart.find(item => item.productId === product.id);
    
    if (existingItem) {
      setCart(cart.map(item =>
        item.productId === product.id
          ? { ...item, quantity: item.quantity + quantity }
          : item
      ));
    } else {
      setCart([...cart, {
        productId: product.id,
        name: product.name,
        price: product.baseUnit.price.USD,
        quantity,
        unitId: product.baseUnit.id
      }]);
    }
  };

  const handleUpdateQuantity = (productId: string, quantity: number) => {
    if (quantity <= 0) {
      setCart(cart.filter(item => item.productId !== productId));
    } else {
      setCart(cart.map(item =>
        item.productId === productId
          ? { ...item, quantity }
          : item
      ));
    }
  };

  const handleRemoveItem = (productId: string) => {
    setCart(cart.filter(item => item.productId !== productId));
  };

  const handleCheckout = () => {
    if (!selectedCustomer) {
      alert('Por favor seleccione un cliente antes de procesar la venta');
      return;
    }
    setShowPayment(true);
  };

  const handlePaymentComplete = async (paymentDetails: any) => {
    if (!selectedCustomer || !currentSession) return;

    try {
      // Iniciar transacción
      await pool.query('BEGIN');

      const sale: Sale = {
        id: crypto.randomUUID(),
        customerId: selectedCustomer.id,
        customer: selectedCustomer,
        date: new Date().toISOString(),
        subtotal: cart.reduce((sum, item) => sum + (item.price * item.quantity), 0),
        taxAmount: cart.reduce((sum, item) => sum + (item.price * item.quantity), 0) * (taxes[0]?.rate || 16) / 100,
        total: cart.reduce((sum, item) => sum + (item.price * item.quantity), 0) * (1 + (taxes[0]?.rate || 16) / 100),
        totalBs: cart.reduce((sum, item) => sum + (item.price * item.quantity), 0) * paymentDetails.exchangeRate,
        exchangeRate: paymentDetails.exchangeRate,
        warehouseId: selectedWarehouse || 'default',
        status: 'completed',
        items: cart.map(item => ({
          productId: item.productId,
          name: item.name,
          unitId: item.unitId,
          quantity: item.quantity,
          priceUSD: item.price
        })),
        payments: [paymentDetails],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // Insertar venta
      await pool.query(
        `INSERT INTO sales (
          id, customer_id, date, subtotal, tax_amount, total, total_bs,
          exchange_rate, warehouse_id, status, created_at, updated_at
        ) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12)`,
        [
          sale.id, sale.customerId, sale.date, sale.subtotal, sale.taxAmount,
          sale.total, sale.totalBs, sale.exchangeRate, sale.warehouseId,
          sale.status, sale.createdAt, sale.updatedAt
        ]
      );

      // Insertar items
      for (const item of sale.items) {
        await pool.query(
          `INSERT INTO sale_items (
            sale_id, product_id, unit_id, quantity, price_usd
          ) VALUES ($1, $2, $3, $4, $5)`,
          [sale.id, item.productId, item.unitId, item.quantity, item.priceUSD]
        );

        // Actualizar stock
        await pool.query(
          `UPDATE stock 
           SET quantity = quantity - $1
           WHERE product_id = $2 AND warehouse_id = $3 AND unit_id = $4`,
          [item.quantity, item.productId, sale.warehouseId, item.unitId]
        );
      }

      // Insertar pago
      const payment = sale.payments[0];
      await pool.query(
        `INSERT INTO payments (
          id, sale_id, method, amount_usd, amount_bs,
          exchange_rate, reference, details
        ) VALUES ($1, $2, $3, $4, $5, $6, $7, $8)`,
        [
          payment.id, sale.id, payment.method, payment.amountUSD,
          payment.amountBs, payment.exchangeRate, payment.reference,
          JSON.stringify(payment.details)
        ]
      );

      // Si es pago móvil, registrar detalles
      if (payment.details.mobilePayment) {
        await pool.query(
          `INSERT INTO mobile_payments (
            payment_id, phone_number, bank_name, reference_number,
            amount, reference_date
          ) VALUES ($1, $2, $3, $4, $5, $6)`,
          [
            payment.id,
            payment.details.mobilePayment.phoneNumber,
            payment.details.mobilePayment.bankName,
            payment.details.mobilePayment.referenceNumber,
            payment.details.mobilePayment.amount,
            payment.details.mobilePayment.referenceDate
          ]
        );
      }

      // Si es Zelle, registrar detalles
      if (payment.details.zellePayment) {
        await pool.query(
          `INSERT INTO zelle_payments (
            payment_id, email, reference_number, amount, reference_date
          ) VALUES ($1, $2, $3, $4, $5)`,
          [
            payment.id,
            payment.details.zellePayment.email,
            payment.details.zellePayment.referenceNumber,
            payment.details.zellePayment.amount,
            payment.details.zellePayment.referenceDate
          ]
        );
      }

      // Si hay vuelto para agregar al saldo
      if (payment.details.addToBalance && payment.details.change) {
        await pool.query(
          `INSERT INTO customer_balances (customer_id, amount)
           VALUES ($1, $2)
           ON CONFLICT (customer_id)
           DO UPDATE SET amount = customer_balances.amount + $2`,
          [selectedCustomer.id, payment.details.change]
        );
      }

      // Calcular comisión del cajero si está habilitado
      const commissionRate = 0.01; // 1% de comisión
      await pool.query(
        `INSERT INTO cashier_commissions (
          id, user_id, sale_id, amount, rate, status
        ) VALUES ($1, $2, $3, $4, $5, $6)`,
        [
          crypto.randomUUID(),
          user.id,
          sale.id,
          sale.total * commissionRate,
          commissionRate,
          'pending'
        ]
      );

      await pool.query('COMMIT');

      // Limpiar el carrito y cerrar modal
      setCart([]);
      setShowPayment(false);
      setSelectedCustomer(null);

    } catch (error) {
      await pool.query('ROLLBACK');
      console.error('Error al procesar la venta:', error);
      alert('Error al procesar la venta. Por favor intente nuevamente.');
    }
  };

  const subtotal = cart.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const taxAmount = subtotal * (taxes[0]?.rate || 16) / 100;
  const total = subtotal + taxAmount;

  return (
    <div className="h-[calc(100vh-4rem)] flex">
      <div className="flex-1 flex flex-col">
        <div className="bg-white p-4 shadow">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
              <ShoppingCart className="h-6 w-6" />
              Punto de Venta
            </h1>
          </div>
        </div>

        <div className="p-4">
          <CustomerSelect onCustomerSelect={setSelectedCustomer} />
        </div>

        <div className="p-4">
          <ProductSearch onProductSelect={handleAddToCart} />
        </div>

        <div className="grid grid-cols-3 gap-4 p-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="flex items-center gap-3">
              <Receipt className="h-8 w-8 text-blue-500" />
              <div>
                <p className="text-sm text-gray-500">Ventas del Día</p>
                <p className="text-xl font-semibold text-gray-900">0</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="flex items-center gap-3">
              <DollarSign className="h-8 w-8 text-green-500" />
              <div>
                <p className="text-sm text-gray-500">Total del Día</p>
                <p className="text-xl font-semibold text-gray-900">${0.00}</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="flex items-center gap-3">
              <Package className="h-8 w-8 text-purple-500" />
              <div>
                <p className="text-sm text-gray-500">Productos Vendidos</p>
                <p className="text-xl font-semibold text-gray-900">0</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CartPanel
        items={cart}
        onUpdateQuantity={handleUpdateQuantity}
        onRemoveItem={handleRemoveItem}
        onCheckout={handleCheckout}
        total={total}
        subtotal={subtotal}
        taxAmount={taxAmount}
      />

      {showPayment && selectedCustomer && (
        <PaymentModal
          total={total}
          customerId={selectedCustomer.id}
          customerBalance={customerBalance}
          onClose={() => setShowPayment(false)}
          onComplete={handlePaymentComplete}
        />
      )}

      {showCashDialog && (
        <CashRegisterDialog
          type={cashDialogType}
          onClose={() => setShowCashDialog(false)}
        />
      )}
    </div>
  );
}